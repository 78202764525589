import type { IconProps } from "~/components/types";

const OrdersIcon = ({
  fill = "none",
  size = 40,
  iconFill = "#121212",
  ...props
}: IconProps & { iconFill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 40 41"
      fill={fill}
      {...props}
    >
      <path
        d="M1.66797 33.8281V31.0502H9.97339V27.1335H3.33464V24.356H9.97339V20.4394H5.19589V17.6615H9.97339V12.4115L6.72339 4.99479L9.2513 3.82812L12.9459 12.2169H33.6126L30.3067 4.99479L32.8346 3.82812L36.668 12.2169V33.8281H1.66797ZM19.8067 21.8281H26.8626C27.2559 21.8281 27.5857 21.6945 27.8521 21.4273C28.1182 21.1603 28.2513 20.8294 28.2513 20.4344C28.2513 20.0394 28.1182 19.7099 27.8521 19.446C27.5857 19.1822 27.2559 19.0502 26.8626 19.0502H19.8067C19.4134 19.0502 19.0835 19.1838 18.8171 19.451C18.551 19.7183 18.418 20.0492 18.418 20.444C18.418 20.839 18.551 21.1684 18.8171 21.4323C19.0835 21.6962 19.4134 21.8281 19.8067 21.8281ZM12.7513 31.0502H33.8901V14.9948H12.7513V31.0502Z"
        fill={iconFill}
      />
    </svg>
  );
};

export default OrdersIcon;
