import type { IconProps } from "~/components/types";

const AddressIcon = ({
  fill = "none",
  size = 40,
  iconFill = "#121212",
  ...props
}: IconProps & { iconFill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 40 41"
      fill={fill}
      {...props}
    >
      <path
        d="M14.9993 22.1693H18.3327V17.5859H21.666V22.1693H24.9993V14.2526L19.9993 10.9193L14.9993 14.2526V22.1693ZM19.9993 32.7526C23.3882 29.6415 25.9021 26.8151 27.541 24.2734C29.1799 21.7318 29.9993 19.4748 29.9993 17.5026C29.9993 14.4748 29.0341 11.9957 27.1035 10.0651C25.173 8.13455 22.8049 7.16927 19.9993 7.16927C17.1938 7.16927 14.8257 8.13455 12.8952 10.0651C10.9646 11.9957 9.99935 14.4748 9.99935 17.5026C9.99935 19.4748 10.8188 21.7318 12.4577 24.2734C14.0966 26.8151 16.6105 29.6415 19.9993 32.7526ZM19.9993 37.1693C15.5271 33.3637 12.1868 29.829 9.97852 26.5651C7.77018 23.3012 6.66602 20.2804 6.66602 17.5026C6.66602 13.3359 8.00629 10.0165 10.6868 7.54427C13.3674 5.07205 16.4716 3.83594 19.9993 3.83594C23.5271 3.83594 26.6313 5.07205 29.3118 7.54427C31.9924 10.0165 33.3327 13.3359 33.3327 17.5026C33.3327 20.2804 32.2285 23.3012 30.0202 26.5651C27.8118 29.829 24.4716 33.3637 19.9993 37.1693Z"
        fill={iconFill}
      />
    </svg>
  );
};

export default AddressIcon;
