import { NavLink } from "@remix-run/react";

import { P } from "~/components/ui/text";
import { cn } from "~/lib/ui";

type AccountNavLinkProps = {
  icon: React.ReactNode;
  label: string;
  description: string;
  link: string;
  isActive: boolean;
  className?: string;
  anchor?: boolean;
};

const AccountNavLink = ({
  icon,
  label,
  description,
  link,
  isActive,
  className,
  anchor = false,
}: AccountNavLinkProps) => {
  const content = (
    <div
      className={cn(
        "grid grid-cols-[auto_1fr] gap-3 rounded-md p-3 shadow-accountNavigationLinks hover:cursor-pointer",
        className,
        isActive ? "border-[2px] border-brand-primary" : "",
      )}
    >
      <div className="flex items-center">{icon}</div>
      <div>
        <P className="font-bold">{label}</P>
        <P>{description}</P>
      </div>
    </div>
  );
  if (anchor) {
    return (
      <a
        href={link}
        className={"hover:no-underline"}
        onClick={e => {
          e.stopPropagation();
        }}
        target="_self"
        data-no-delegate-anchor="true"
      >
        {content}
      </a>
    );
  }
  return (
    <NavLink prefetch="intent" to={link} className={"hover:no-underline"}>
      {content}
    </NavLink>
  );
};

export default AccountNavLink;
