import { useMemo } from "react";

import type { ShouldRevalidateFunction } from "@remix-run/react";
import {
  Outlet,
  useLoaderData,
  useLocation,
  useMatches,
} from "@remix-run/react";
import type { HeadersFunction, SerializeFrom } from "@remix-run/server-runtime";
import { defer } from "@remix-run/server-runtime";

import {
  commitSession,
  isAuthenticatedUser,
  requireAuthenticatedUser,
} from "@commerce/.server/sessions.server";

import type { Customer } from "@commerce/app.types.legacy";
import Breadcrumbs from "@commerce/components/breadcrumbs";
import { Label } from "@radix-ui/react-label";

import { SkeletonAwait } from "~/components/suspense/skeleton";
import { cn } from "~/lib/ui";
import { useMediaScreen } from "~/lib/utils/screens";
import { invariant } from "~/lib/utils/utils";

import { AccountNavLinks } from "./components/account-nav-links";
import { ACCOUNT_PAGES } from "./consts";

export type { SerializeFrom };

export const handle: AppHandle = {
  rootLayoutTransition: false,
};

export const loader = async ({ context }: LoaderArgs) => {
  const { locale, request, session } = context;
  const isLoggedin = isAuthenticatedUser();
  const searchParams = new URL(request.url).searchParams;
  const $orderId = searchParams.get("orderId");
  if ($orderId && !isLoggedin) {
    session.flash(
      "afterLoginRedirectUrl",
      ACCOUNT_PAGES.orders.path + "?orderId=" + $orderId,
    );

    await commitSession();
  }
  const user: Customer = await requireAuthenticatedUser("/login");
  const cf = request?.cf || {};

  return defer(
    {
      user,
      locale,
      location: {
        city: cf.city as string | undefined, // ts infers props as unknown and can't figure how to fix it
        countryCode: cf.country as string | undefined,
        postalCode: cf.postalCode as string | undefined,
        stateCode: cf.regionCode as string | undefined,
        stateName: cf.region as string | undefined,
      } as const,
    },
    {
      headers: {
        "Cache-Control": "no-store",
      },
    },
  );
};
export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return {
    "cache-control":
      loaderHeaders.get("cache-control") ??
      "no-store, max-age=0, must-revalidate",
  };
};

export const useAccountData = (): SerializeFrom<typeof loader> => {
  const matches = useMatches();
  // Expect layout route to exist and to have the data loaded
  const data = matches[matches.length - 2].data as SerializeFrom<typeof loader>;

  invariant(
    data,
    "useAccountData must be used within the account/_layout route",
  );
  return data;
};

export const shouldRevalidate: ShouldRevalidateFunction = ctx => {
  /* if we are navigating to /addresses again revalidate */
  if (
    ctx.currentUrl.pathname !== ctx.nextUrl.pathname &&
    ctx.nextUrl.pathname.includes("/account/addresses")
  ) {
    return true;
  }
  /* otherwise deal only with POST requests */
  if (ctx.formMethod !== "POST") {
    return false;
  }
  /* Optimistic UI relies on data not being changed so in these actions */
  /* don't update the data */
  if (["deleteAddress", "undoDelete"].find(e => ctx.formAction?.includes(e))) {
    return false;
  }
  return true;
};

export default function PageLayout() {
  const nav = useLocation();
  const { user } = useLoaderData<typeof loader>();
  const { isMobile } = useMediaScreen();
  const isAccountPage = useMemo(
    () => nav.pathname === "/account",
    [nav.pathname],
  );

  const activePage = useMemo(() => {
    const currentPage = Object.values(ACCOUNT_PAGES).find(
      pageData => pageData.path === nav.pathname,
    );

    if (currentPage) {
      return currentPage;
    }

    return null;
  }, [nav.pathname]);

  const breadcrumbs = useMemo(() => {
    if (activePage && activePage.id !== ACCOUNT_PAGES.dashboard.id) {
      return [
        {
          code: "account",
          name: "My Account",
          url: "/account/accelerated-rewards",
        },
        { code: activePage.id, name: activePage.name },
      ];
    }

    return [{ code: "account", name: "My Account" }];
  }, [activePage]);

  return (
    <div className="mx-auto min-h-screen max-w-1920 px-6 lg:px-10 xl:px-24">
      <div
        className={cn(isMobile && isAccountPage ? "block" : "hidden md:block")}
      >
        <Breadcrumbs
          categories={breadcrumbs}
          pageName={activePage ? activePage.name : ACCOUNT_PAGES.dashboard.name}
        />
      </div>

      <div className="mb-4 w-full text-left text-lg md:flex-row ">
        <div
          className={cn(
            isMobile && isAccountPage
              ? "block w-full justify-center align-top"
              : "hidden md:block",
          )}
        >
          <div className="m-0 flex w-full max-w-sm list-none flex-row justify-between gap-5 pb-[24px] align-top text-lg md:w-fit md:flex-col md:justify-start md:py-[18px] lg:py-[14px]">
            <Label className="text-2xl font-semibold leading-6">
              Welcome,{" "}
              <SkeletonAwait
                resolve={user}
                className="inline-block h-6 w-12 align-middle"
              >
                {user => user.cdcUser?.profile?.firstName || ""}
              </SkeletonAwait>
            </Label>
          </div>
        </div>
        <div className="grid grid-cols-[1fr] p-0 md:grid-cols-[auto_1fr] md:gap-8 md:py-6 lg:gap-[60px] ">
          <div
            className={cn(
              "hidden md:flex md:max-w-[277px] lg:max-w-[324px] xl:max-w-[393px]",
              nav.pathname === ACCOUNT_PAGES.dashboard.path ? "sm:flex" : "",
            )}
          >
            <AccountNavLinks />
          </div>
          <div
            className="animate-reveal flex-col flex-wrap content-center py-6 md:content-baseline md:p-0"
            key={`key${nav?.pathname || nav.key || "default"}`}
          >
            <div className="w-full">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
