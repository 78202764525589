import { NavLink, useLocation } from "@remix-run/react";

import { Button } from "~/components/ui/button";
import AcceleratedRewardsIcon from "~/components/ui/icons/accelerated-rewards-icon";
import AddressIcon from "~/components/ui/icons/address-icon";
import LogoutIcon from "~/components/ui/icons/logout-icon";
import OrdersIcon from "~/components/ui/icons/orders-icon";
import ProfileIcon from "~/components/ui/icons/profile-icon";
import { useURL } from "~/contexts";
import { cn } from "~/lib/ui";

import { ACCOUNT_PAGES, ICON_ACTIVE_COLOR } from "../consts";
import AccountNavLink from "./account-nav-link";

export const AccountNavLinks = ({ className }: { className?: string }) => {
  const nav = useLocation();
  const url = useURL();

  return (
    <div
      className={cn(
        "text-accentalpha-11 flex w-fit flex-col gap-3 text-base font-light sm:w-full md:w-fit",
        className ?? "",
      )}
    >
      <AccountNavLink
        icon={
          <AcceleratedRewardsIcon
            iconFill={
              nav.pathname === ACCOUNT_PAGES.acceleratedRewards.path
                ? ICON_ACTIVE_COLOR
                : undefined
            }
          />
        }
        label="My Accelerate Rewards"
        description="View Credits earned, available balance and special offers"
        link={url(ACCOUNT_PAGES.acceleratedRewards.path)}
        isActive={nav.pathname === ACCOUNT_PAGES.acceleratedRewards.path}
      />
      <AccountNavLink
        icon={
          <ProfileIcon
            iconFill={
              nav.pathname === ACCOUNT_PAGES.personalDetails.path
                ? ICON_ACTIVE_COLOR
                : undefined
            }
          />
        }
        label="My Details"
        description="Manage your personal information, update email and password"
        link={url(ACCOUNT_PAGES.personalDetails.path)}
        isActive={nav.pathname === ACCOUNT_PAGES.personalDetails.path}
      />
      <AccountNavLink
        icon={
          <OrdersIcon
            iconFill={
              nav.pathname === ACCOUNT_PAGES.orders.path
                ? ICON_ACTIVE_COLOR
                : undefined
            }
          />
        }
        label="My Orders"
        description="View past orders & track current orders"
        link={url(ACCOUNT_PAGES.orders.path)}
        isActive={nav.pathname === ACCOUNT_PAGES.orders.path}
      />
      <AccountNavLink
        icon={
          <AddressIcon
            iconFill={
              nav.pathname === ACCOUNT_PAGES.addresses.path
                ? ICON_ACTIVE_COLOR
                : undefined
            }
          />
        }
        label="My Address Book"
        description="Manage your saved addresses"
        link={url(ACCOUNT_PAGES.addresses.path)}
        isActive={nav.pathname === ACCOUNT_PAGES.addresses.path}
        // We are using anchor to prevent browser navigation
        // the reason for this is a bug, where the layout's
        // shouldRevalidate is not called
        anchor={true}
      />
      <NavLink
        to={url("/logout")}
        className={
          "mt-6 flex justify-center sm:justify-center md:justify-start"
        }
      >
        <Button variant="link" className="flex gap-[5px] px-6 py-[15px]">
          <LogoutIcon />
          Logout
        </Button>
      </NavLink>
    </div>
  );
};
